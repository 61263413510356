import { LogoSymbol } from '../shared/Logo'
import { SignupForm } from './SignupForm'
import {
  Box,
  Button,
  chakra,
  Container,
  HStack,
  Stack,
  VStack,
  Link,
  Center
} from '@chakra-ui/react'
import React, { useEffect } from "react";

export default function Signup() {

  useEffect(() => {
    document.title = "Signup | AI Empowerment";
  }, []);

  return (
    <Box bg='gray.50' minHeight='100vh'>
      <Container
        maxWidth='lg'
        paddingY={{ base: '12vh', md: '16vh' }}
        paddingX={{ sm: '8' }}
      >
        <Stack
          spacing='8'
          paddingY={{ sm: '8' }}
          paddingX={{ base: '4', sm: '10' }}
          boxShadow={{ sm: 'md' }}
          borderRadius={{ sm: '3xl' }}
          backgroundColor='white'
        >
          <VStack spacing='6'>
            <LogoSymbol />
            <Stack spacing={{ base: '2', md: '3' }} textAlign='center'>
              <chakra.h1
                textStyle='heading/sm'
                fontWeight='medium'
                textAlign='center'
              >
                Sign up to AI Empowerment
              </chakra.h1>
              <HStack spacing='1' justify='center'>
                <chakra.p>Already have an account?</chakra.p>

                <Button
                  as={Link}
                  href='/login'
                  variant='link'
                  colorScheme='purple'
                  fontWeight='medium'
                >
                  Log in
                </Button>
              </HStack>
            </Stack>
          </VStack>

          <SignupForm />
        </Stack>
        <Center>
          <chakra.p
            textStyle='body/sm'
            color='gray.500'
            px={'5'}
            marginTop={{ base: '8', sm: '10' }}
          >
            By signing up, you agree to our{' '}
            <Link color='purple.500' href='https://aiempowerment.ai/terms'>
              Terms of Service
            </Link>{' '}
            and{' '}
            <Link color='purple.500' href='https://aiempowerment.ai/terms'>
              Privacy Policy
            </Link>
            .
          </chakra.p>
        </Center>
      </Container>
    </Box>
  )
}
