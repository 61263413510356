
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
   faBrainCircuit, 
   faHeadphones,
   faBriefcase,
   faCode,
   faPenNib,
   faImageLandscape,
   faBullseyePointer,
   faSeedling,
   faFilm,
   faTypewriter,
} from '@fortawesome/pro-regular-svg-icons'

const categories = [
   {
      "handle": "ai-empowerment",
      "title": "AI Empowerment Tools",
      "description": "Custom tools and GPT's build by AI Empowerment.",
      "icon": <FontAwesomeIcon style={{paddingRight: '3px'}} color="white" fontSize="28px"  icon={faBrainCircuit} />,
      "color": 'linear(to-r, blue.400, purple.500)'
   },
   {
      "handle": "audio",
      "title": "Audio",
      "description": "AI-powered tools for audio editing, enhancement, generation, and analysis to elevate auditory content.",
      "icon": <FontAwesomeIcon style={{paddingRight: '3px'}} color="#718096" fontSize="20px"  icon={faHeadphones} />,
      "color": "red.500",
   },
   {
      "handle": "business",
      "title": "Business",
      "description": "AI tools tailored for business analytics, process automation, decision-making support, and operational efficiency.",
      "icon": <FontAwesomeIcon style={{paddingRight: '3px'}} color="#718096" fontSize="20px"  icon={faBriefcase} />,
      "color": "purple.500"
   },
   {
      "handle": "code",
      "title": "Code",
      "description": "AI platforms for coding assistance, automated code generation, debugging, and enhancing software development.",
      "icon": <FontAwesomeIcon style={{paddingRight: '3px'}} color="#718096" fontSize="20px"  icon={faCode} />,
      "color": "teal.500"
   },
   {
      "handle": "design",
      "title": "Design",
      "description": "Advanced AI applications for design tasks, including graphic design, UX/UI enhancements, and creative conceptualization.",
      "icon": <FontAwesomeIcon style={{paddingRight: '3px'}} color="#718096" fontSize="20px"  icon={faPenNib} />,
      "color": "red.500"
   },
   {
      "handle": "image",
      "title": "Image",
      "description": "Innovative AI solutions for image editing, enhancement, generation, and analysis to elevate visual content.",
      "icon": <FontAwesomeIcon style={{paddingRight: '3px'}} color="#718096" fontSize="20px"  icon={faImageLandscape} />,
      "color": "blue.500"
   },
   {
      "handle": "marketing",
      "title": "Marketing",
      "description": "AI-driven marketing tools for campaign automation, market analysis, customer insights, and digital strategy optimization.",
      "icon": <FontAwesomeIcon style={{paddingRight: '3px'}} color="#718096" fontSize="20px"  icon={faBullseyePointer} />,
      "color": "green.500"
   },
   {
      "handle": "personal",
      "title": "Personal Growth",
      "description": "AI tools focused on personal development, including learning new skills, productivity enhancement, and lifestyle improvement.",
      "icon": <FontAwesomeIcon style={{paddingRight: '3px'}} color="#718096" fontSize="20px"  icon={faSeedling} />,
      "color": "cyan.500"
   },
   {
      "handle": "video",
      "title": "Video",
      "description": "AI-powered tools for video production, editing, analysis, and enhancement, simplifying complex video tasks.",
      "icon": <FontAwesomeIcon style={{paddingRight: '3px'}} color="#718096" fontSize="20px"  icon={faFilm} />,
      "color": "purple.500"
   },
   {
      "handle": "writing",
      "title": "Writing",
      "description": "AI tools designed to enhance writing skills, automate content creation, and improve editing and proofreading processes.",
      "icon": <FontAwesomeIcon style={{paddingRight: '3px'}} color="gray.300" fontSize="20px"  icon={faTypewriter} />,
      "color": "teal.500"
   }
]

export default categories;