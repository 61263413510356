import {
  Button,
  chakra,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
  ButtonGroup,
  Box,
  useToast,
  Link,
  Spinner,
} from '@chakra-ui/react'
import { useState } from 'react';
import { FaGoogle, FaFacebook } from 'react-icons/fa';
import { auth, signInWithEmailAndPassword } from '../../firebase';
import { useSocialMediaAuth } from '../../SocialAuth';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

export function LoginForm() {

  const { handleGoogleSignUp, handleFacebookSignUp } = useSocialMediaAuth();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false); 
  const [password, setPassword] = useState('');
  const toast = useToast();
  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleForgotPassword = async (email) => {
    setIsLoading(true);
    if (!email) {
      toast({
        title: "Error",
        description: "Please input your email address",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: 'top',

      });
      setIsLoading(false);
      return
    }
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
      toast({
        title: "Password reset email sent",
        description: "Check your inbox for further instructions",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
      setIsLoading(false);
    } catch (error) {
      let errorMessage = "An error occurred while sending the password reset email. Please try again.";
      if (error.code === "auth/invalid-email") {
        errorMessage = "Invalid email address. Please enter a valid email address.";
      } else if (error.code === "auth/user-not-found") {
        errorMessage = "User not found. Please check your email address and try again.";
      } else if (error.code === "auth/network-request-failed") {
        errorMessage = "Network error occurred. Please check your internet connection and try again.";
      }
      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
      setIsLoading(false);
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      toast({
        title: 'Login Successful',
        description: 'You have successfully logged in.',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });

      navigate('/');

    } catch (error) {
      console.log(error);
      let errorMessage = 'An error occurred while logging in. Please try again.';
      if (error.code === 'auth/user-not-found') {
        errorMessage = 'Invalid email or password. Please check your credentials and try again.';
      } else if (error.code === 'auth/invalid-email') {
        errorMessage = 'Invalid email or password. Please check your credentials and try again.';
      } else if (error.code === 'auth/invalid-credential') {
        errorMessage = 'Invalid email or password. Please check your credentials and try again.';
      } else if (error.code === 'auth/network-request-failed') {
        errorMessage = 'Network error occurred. Please check your internet connection and try again.';
      } else if (error.code === 'auth/too-many-requests') {
        errorMessage = 'Too many login attempts. Please try again later.';
      }

      toast({
        title: 'Login Error',
        description: errorMessage,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',

      });
    }
  };

  return (
    <form
      id='login-form'
      onSubmit={handleLogin}
    >
      <Stack spacing='5'>
        <FormControl id='email' isRequired>
          <FormLabel>Email</FormLabel>
          <Input type='email' value={email} onChange={handleEmailChange} />
        </FormControl>
        <FormControl id='password' isRequired>
          <FormLabel>Password</FormLabel>
          <Input type='password' value={password} onChange={handlePasswordChange} />
        </FormControl>

        <Button colorScheme='purple' type='submit'>
          Login
        </Button>
        <HStack>
          <Divider />
          <chakra.p textStyle='body/sm' whiteSpace='nowrap'>
            or sign in with
          </chakra.p>
          <Divider />
        </HStack>
        <ButtonGroup variant='outline' spacing='4' width='full'>

          <Button key="Google" width='full' onClick={handleGoogleSignUp}>
            <Box as='span' srOnly>
              Sign in with Google
            </Box>
            <FaGoogle size={20} />
          </Button>

          <Button key="Facebook" width='full' onClick={handleFacebookSignUp}>
            <Box as='span' srOnly>
              Sign in with Facebook
            </Box>
            <FaFacebook size={20} />
          </Button>
        </ButtonGroup>


        { isLoading ? 
          <Spinner size="xs" mt="5" mx="auto" /> 
          : 
          <Link mx="auto" mt="4" color="gray.500" fontSize="xs" onClick={() => handleForgotPassword(email)}>Forgot Password?</Link>
        }
        

      </Stack>
    </form>
  )
}
