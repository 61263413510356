import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getAuth, signOut, GoogleAuthProvider, FacebookAuthProvider, signInWithPopup, signInWithEmailAndPassword } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey:  process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "app.aiempowerment.ai",
  projectId: "ai-empowerment",
  storageBucket: "ai-empowerment.appspot.com",
  messagingSenderId: "202798654374",
  appId:  process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: "G-ETVQNPVL59"
};


const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const functions = getFunctions(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const logout = () => {
  signOut(auth);
};

export { db, functions, auth, googleProvider, facebookProvider, signInWithPopup, signInWithEmailAndPassword, logout };