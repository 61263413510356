import { extendTheme } from '@chakra-ui/react'
import '@fontsource/inter/400.css'; // Regular weight


export const theme = extendTheme({
  fonts: {
    heading: 'Inter',
    body: 'Inter',
  },
  fontSizes: {
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.75rem',
    '4xl': '2.5rem',
    '5xl': '3rem',
    '6xl': '3.5rem',
  },
  textStyles: {
    'heading/xl': {
      fontFamily: 'heading',
      fontSize: { base: '4xl', lg: '6xl' },
      fontWeight: 'medium',
      letterSpacing: 'tighter',
      lineHeight: '110%',
    },
    'heading/lg': {
      fontFamily: 'heading',
      fontSize: { base: '3xl', lg: '4xl' },
      fontWeight: 'medium',
      letterSpacing: 'tighter',
      lineHeight: '130%',
    },
    heading: {
      fontFamily: 'heading',
      fontSize: { base: '2xl', lg: '3xl' },
      fontWeight: 'medium',
      letterSpacing: 'tighter',
      lineHeight: '130%',
    },
    'heading/sm': {
      fontFamily: 'heading',
      fontSize: { base: 'xl', lg: '2xl' },
      fontWeight: 'medium',
    },
    'body/lg': {
      fontSize: { base: '2xl', md: '3xl' },
      fontWeight: 'medium',
    },
    body: {
      fontFamily: 'body',
      fontSize: { base: 'md', lg: 'xl' },
    },
    'body/sm': {
      fontSize: 'sm',
    },
  },
  styles: {
    global: {
      'html, body': {
        fontFeatureSettings: '"pnum" on, "lnum" on, "salt" on',
        WebkitTextSizeAdjust: 'none',
      },
    },
  },
})
