import React, {useEffect} from 'react';
import { Box } from '@chakra-ui/react';
import { TopNavbar } from '../shared/TopNavbar';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, logout } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { UserCard } from './UserCard/UserCard';

const Dashboard = () => {
   const [user, loading] = useAuthState(auth);
   const navigate = useNavigate();

   useEffect(() => {
      document.title = "Account | AI Empowerment";
    }, []);

   useEffect(() => {
      if (loading) return;
      if (!user) return navigate("/login");
   }, [user, loading, navigate]);
   
   return (
      <Box bg={'gray.50'} minH="100vh">
         <TopNavbar user={user} loading={loading} />
         <Box as="section" py="12">
            <Box maxW="container.xl" mx="auto" px={{ base: '6', lg: '8' }}>
               <UserCard user={user} loading={loading} />
            </Box>
         </Box>
      </Box>
   );
};

export default Dashboard;
