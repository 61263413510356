import React, { useEffect, useState } from 'react';
import {
   Button,
   IconButton,
   Stack,
   StackDivider,
   Text,
   Link,
   Tag,
   AlertDialog,
   AlertDialogBody,
   AlertDialogFooter,
   AlertDialogHeader,
   AlertDialogContent,
   AlertDialogOverlay,
   Spinner,
} from '@chakra-ui/react';
import { FiEdit2, FiTrash2, FiList } from 'react-icons/fi';
import { FaPlus } from 'react-icons/fa';
import { collection, query, where, orderBy, limit, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import { ArticlesSkeleton } from './ArticlesSkeleton';


export function LatestArticles(props) {
   const [articles, setArticles] = useState([]);
   const [user, loading] = useAuthState(auth);
   const [loadingArticles, setLoadingArticles] = useState(true);
   const [deleteConfirmation, setDeleteConfirmation] = useState(null);
   const [deleteArticleId, setDeleteArticleId] = useState(null);
   const [deletingArticleId, setDeletingArticleId] = useState(null);
   const loadingRows = [1, 2, 3];

   useEffect(() => {
      if (loading) return;
      if (user) fetchArticles();
   }, [user, loading]);

   const fetchArticles = async () => {
      try {
         const user = auth.currentUser;
         const articlesRef = collection(db, 'Articles');
         const q = query(articlesRef, where('userId', '==', user.uid), where('deleted', '==', false), orderBy('finishedAt', 'desc'), limit(3));

         const querySnapshot = await getDocs(q);
         const fetchedArticles = querySnapshot.docs.map((doc) => {
            const data = doc.data();
            data.id = doc.id; // Add the .id attribute to each article
            return data;
         });

         setArticles(fetchedArticles);
         setLoadingArticles(false);
      } catch (error) {
         console.error('Error fetching articles:', error);
         setLoadingArticles(false);
      }
   };

   const handleDeleteConfirmation = (articleId) => {
      setDeleteConfirmation(true);
      setDeleteArticleId(articleId);
   };


   const handleDeleteArticle = async () => {

      try {
         console.log('Deleting article:', deleteArticleId);
         setDeletingArticleId(deleteArticleId);

         const articleRef = doc(db, 'Articles', deleteArticleId);
         await deleteDoc(articleRef);

         await fetchArticles();
         //setArticles(articles.filter((article) => article.id !== deleteArticleId));
      } catch (error) {
         console.error('Error deleting article:', error);
      } finally {
         setDeleteConfirmation(false);
         setDeleteArticleId(null);
         setDeletingArticleId(null);
      }
   };


   return (
      <>
         <Stack spacing="5" divider={<StackDivider />}>
            <Stack justify="space-between" direction={{ base: 'column', sm: 'row' }} spacing="5">
               <Stack spacing="1">
                  <Text fontSize="xl" fontWeight="bold">
                     My Latest Articles
                  </Text>
                  <Text textStyle="sm" color="gray.600">
                     Articles generated with our free GPT: Alice, the SEO Wizard.
                  </Text>
               </Stack>
               <Button colorScheme="purple" size="sm" target="_blank" as={Link} href="https://chat.openai.com/g/g-DA2kvvY7Y-alice-the-seo-blogging-wizard" leftIcon={<FaPlus />}>
                  New Article
               </Button>
            </Stack>
            {articles.map((article, id) => (
               <React.Fragment key={id}>
                  <Stack justify="space-between" direction="row" spacing="4">
                     <Stack flex={1} spacing="0.5" fontSize="sm">
                        <Text color="gray.800" fontWeight="bold">
                           <Link href={`/articles/${article.id}`}>{article.title}</Link>
                        </Text>
                        <Text color="gray.500">
                           {article.description.split(' ').slice(0, 50).join(' ')}
                           {article.description.split(' ').length > 50 ? '...' : ''}
                        </Text>
                     </Stack>
                     <Stack>
                        <Tag bg="gray.600" size="sm" textColor="gray.100" alignSelf="center" justifySelf="center" textAlign="center">{article.finishedAt.toDate().toLocaleDateString()}</Tag>
                        <Stack direction={{ base: 'column', sm: 'row' }} spacing={{ base: '0', sm: '1' }}>
                           <IconButton as="a" title="Edit Article" href={`/articles/${article.id}`} icon={<FiEdit2 />} variant="tertiary" aria-label="Edit Article" />
                           <Button title="Delete Article" variant="tertiary" aria-label="Delete Article" onClick={() => handleDeleteConfirmation(article.id)} >
                              {deletingArticleId === article.id ? <Spinner size="sm" /> : <FiTrash2 />}

                           </Button>
                        </Stack>
                     </Stack>
                  </Stack>
                  {id !== articles.length - 1 && <StackDivider />}
               </React.Fragment>
            ))}
            {(!loadingArticles && articles.length === 0) && (
               <Text my={5} align="center" color="gray.800" fontWeight="bold">
                  No articles yet.
               </Text>
            )}

            {(!loadingArticles && articles.length > 0) && (
               <Button size="sm" variant="ghost" as={Link} href="/articles" leftIcon={<FiList />}>
                  View All Articles
               </Button>
            )}

            {loadingArticles && loadingRows.map((tool, id) => (
               <ArticlesSkeleton key={id} length={articles.length} id={id} />
            ))}


         </Stack>
         <AlertDialog
            isOpen={deleteConfirmation}
            isCentered
            leastDestructiveRef={null}
            onClose={() => setDeleteConfirmation(false)}
         >
            <AlertDialogOverlay>
               <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                     Delete Article
                  </AlertDialogHeader>

                  <AlertDialogBody>
                     Are you sure you want to delete this article?
                  </AlertDialogBody>

                  <AlertDialogFooter>
                     <Button onClick={() => setDeleteConfirmation(false)}>
                        Cancel
                     </Button>
                     <Button colorScheme="red" onClick={handleDeleteArticle} ml={3}>
                        Delete
                     </Button>
                  </AlertDialogFooter>
               </AlertDialogContent>
            </AlertDialogOverlay>
         </AlertDialog>
      </>
   );
}
