import {
  Center, Alert,
  AlertIcon,
  Link,
} from '@chakra-ui/react';
import React from 'react';

export function loginWarning(article) {
  const expirationDate = new Date(article.finishedAt._seconds * 1000);
  expirationDate.setDate(expirationDate.getDate() + 7);
  const formattedExpirationDate = expirationDate.toLocaleDateString();
  return <Center mb={6}>
    <Alert borderRadius={6} maxWidth={"3xl"} status='info'>
      <AlertIcon />
      <Link href="/login">Log in now to save this article to your account for free, otherwise it will expire on {formattedExpirationDate}.</Link>
    </Alert>
  </Center>;
}
