import {
  Box,
  Button,
  ButtonGroup,
  Container,
  HStack,
  useBreakpointValue,
  useDisclosure,
  Link,
  Avatar,
  SkeletonCircle,
} from '@chakra-ui/react'
import React, { useContext } from "react";
import { Logo } from './Logo'
import { MobileDrawer } from './MobileNavbar'
import { ToggleButton } from './ToggleButton'

export const TopNavbar = ({user, loading=true}) => {


  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  })
  const mobileNavbar = useDisclosure()
  return (
    <Box as="section" mb={5}>
      <Box borderBottomWidth="1px" bg="white" position="relative" zIndex="tooltip">
        <Container maxW='container.xl' py="3">
          <HStack justify="space-between">
            <Link href="/">
              <Logo />
            </Link>
            {(isDesktop && !loading )? (
              <HStack spacing="8">
                { user? (
                  <ButtonGroup size="sm" variant="text" color="gray.500" colorScheme="gray" spacing="5">
                      <Button as={Link} href="/articles" key={1}>My Articles</Button>
                      <Button as={Link} href="/tools" key={2}>Tools</Button>
                      <Button as={Link} href="mailto:travis@aiempowerment.ai" key={3}>Contact</Button>
                  </ButtonGroup>
                ): (
                  <ButtonGroup size="sm" variant="text" color="gray.500" colorScheme="gray" spacing="5">
                      <Button as={Link} href="http://aiempowerment.ai" key={1}>About</Button>
                      <Button as={Link} href="mailto:travis@aiempowerment.ai" key={2}>Contact</Button>
                  </ButtonGroup>
                 )}

                {loading ? (
                  <SkeletonCircle size='12' />
                ) : (
                  user ? (
                    <Link href='/account' >
                      <Avatar bg="purple.300" size="md" name={user.email} src={user.photoURL} />
                    </Link>
                  ) : (
                    <Button as={Link} href='/login' colorScheme="purple">Log in</Button>
                  )
                )}
              </HStack>
            )  : (
              <>
                {!loading && (
                  <>
                    <ToggleButton
                      onClick={mobileNavbar.onToggle}
                      isOpen={mobileNavbar.isOpen}
                      aria-label="Open Menu"
                    />
                    <MobileDrawer currentUser={user} isOpen={mobileNavbar.isOpen} onClose={mobileNavbar.onClose} />
                  </>
                )}
              </>
            )}
          </HStack>
        </Container>
      </Box>
    </Box>
  )
}
