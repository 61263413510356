import { Container } from '../shared/container'
import { LoginForm } from './LoginForm'
import { LogoSymbol } from '../shared/Logo'
import { Box, Button, chakra, HStack, Stack, VStack, Link, useToast } from '@chakra-ui/react'
import AuthContext from '../../AuthContext';
import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from '../../firebase';

export default function Login() {

  const navigate = useNavigate();
  const toast = useToast();
  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    document.title = "Login | AI Empowerment";
  }, []);

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) navigate("/");
  }, [user, loading, navigate]);

  return (
    <Box bg='gray.50' minHeight='100vh'>
      <Container
        maxWidth='lg'
        paddingY={{ base: '12vh', md: '16vh' }}
        paddingX={{ sm: '8' }}
      >
        <Stack
          spacing='8'
          paddingY={{ sm: '8' }}
          paddingX={{ base: '4', sm: '10' }}
          boxShadow={{ sm: 'md' }}
          borderRadius={{ sm: '3xl' }}
          backgroundColor='white'
        >
          <VStack spacing='6'>
            <LogoSymbol />
            <Stack spacing={{ base: '2', md: '3' }} textAlign='center'>
              <chakra.h1 textStyle='heading/sm' fontWeight='medium'>
                Log in to AI Empowerment
              </chakra.h1>
              <HStack spacing='1' justify='center'>
                <chakra.p>Don't have an account?</chakra.p>
                <Button
                  as={Link}
                  href='/signup'
                  variant='link'
                  colorScheme='purple'
                  fontWeight='medium'
                >
                  Sign up
                </Button>
              </HStack>
            </Stack>
          </VStack>
          <LoginForm />
        </Stack>
      </Container>
    </Box>
  )
}
