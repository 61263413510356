import React from 'react';
import {
   Stack, Text, HStack,
   Image,
   LinkBox,
   LinkOverlay,
   Link,
   Icon,
} from '@chakra-ui/react';
import { FiChevronRight } from 'react-icons/fi';

export function ToolRow(props) {
   return (
      <LinkBox>
         <Stack flex={1} direction="row" spacing="4">
            <HStack flex={1} spacing={4}>

               <Image  width={100} boxSize='90px' src={`https://storage.googleapis.com/ai-empowerment.appspot.com/toolLogos/${props.tool.id}.png?q=ss`} alt={props.tool.title} />

               <Stack flex={1} spacing="0.5" fontSize="sm">
                  <Text color="gray.600" fontSize="md" fontWeight="bold">
                     <LinkOverlay isExternal target="_blank" href={`https://${props.tool.url}`}>{props.tool.title}</LinkOverlay>
                  </Text>
                  <Text color="gray.500">
                     {props.tool.description}
                  </Text>
                  {props.isAdmin && (
                     <Link fontSize="xs" mt={1} href={`/admin/tools/edit/${props.tool.id}`} color="teal">Edit</Link>
                  )}
               </Stack>
               <Icon as={FiChevronRight} boxSize="25" color="gray.400" />
            </HStack>
         </Stack>
      </LinkBox>
   );
}
