import {
   Button,
   chakra,
   Divider,
   FormControl,
   FormHelperText,
   FormLabel,
   HStack,
   Input,
   Stack,
   useToast,
   ButtonGroup,
   Box,
 } from '@chakra-ui/react'
 import { useState, useEffect } from 'react'
 import { createUserWithEmailAndPassword } from 'firebase/auth'
 import { FaGoogle, FaFacebook } from 'react-icons/fa';
 import { auth } from '../../firebase';
 import { useSocialMediaAuth } from '../../SocialAuth';
 import { useNavigate } from 'react-router-dom';
 import { useAuthState } from "react-firebase-hooks/auth";

 
 
 export function SignupForm() {
   const [email, setEmail] = useState('')
   const [password, setPassword] = useState('')
   const toast = useToast();
   const navigate = useNavigate();
   const { handleGoogleSignUp, handleFacebookSignUp } = useSocialMediaAuth();
   const [user, loading] = useAuthState(auth);

   useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) navigate("/");
  }, [user, loading, navigate]);
 
   const handleSubmit = async (event) => {
     event.preventDefault();
     try {
       await createUserWithEmailAndPassword(auth, email, password);
       toast({
         title: "Account created.",
         description: "We've created your account.",
         status: "success",
         duration: 9000,
         isClosable: true,
         position: 'top',

       });
     } catch (error) {
       let errorMessage;
       switch (error.code) {
         case 'auth/email-already-in-use':
           errorMessage = 'This email is already in use by another account.';
           break;
         case 'auth/invalid-email':
           errorMessage = 'The email address is not valid.';
           break;
         case 'auth/operation-not-allowed':
           errorMessage = 'Email/password accounts are not enabled. Enable email/password in Firebase Console.';
           break;
         case 'auth/weak-password':
           errorMessage = 'The password is too weak.';
           break;
         default:
           errorMessage = error.message;
           break;
       }
       toast({
         title: "An error occurred.",
         description: errorMessage,
         status: "error",
         duration: 9000,
         isClosable: true,
         position: 'top',

       });
     }
   }
 
   return (
     <form
       id='signup-form'
       onSubmit={handleSubmit}
     >
       <Stack spacing='5'>
         <FormControl id='email' isRequired>
           <FormLabel>Email</FormLabel>
           <Input type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
         </FormControl>
         <FormControl id='password' isRequired>
           <FormLabel>Password</FormLabel>
           <Input type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
           <FormHelperText color='gray.500'>
             At least 8 characters long
           </FormHelperText>
         </FormControl>
 
         <Button colorScheme='purple' type='submit'>
           Create Account
         </Button>
         <HStack>
           <Divider />
           <chakra.p textStyle='body/sm' whiteSpace='nowrap'>
             or sign up with
           </chakra.p>
           <Divider />
         </HStack>
 
         <ButtonGroup variant='outline' spacing='4' width='full'>
 
           <Button key="Google" width='full' onClick={handleGoogleSignUp}>
             <Box as='span' srOnly>
               Sign in with Google
             </Box>
             <FaGoogle size={20} />
           </Button>
 
           {/* <Button key="Apple" width='full' onClick={handleAppleSignUp}>
             <Box as='span' srOnly>
               Sign in with Apple
             </Box>
             <FaApple size={20} />
           </Button> */}
 
           <Button key="Facebook" width='full' onClick={handleFacebookSignUp}>
             <Box as='span' srOnly>
               Sign in with Facebook
             </Box>
             <FaFacebook size={20} />
           </Button>
         </ButtonGroup>
       </Stack>
     </form>
   )
 }
 