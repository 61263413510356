import { Button, Center, HStack, Icon, Square, Text, VStack } from '@chakra-ui/react';
import { FiUploadCloud } from 'react-icons/fi';
import { useDropzone } from 'react-dropzone';

export const Dropzone = ({ onChange, ...oProps}) => {
  const { getRootProps, getInputProps } = useDropzone({

    onDrop: (acceptedFiles) => {    
      onChange(acceptedFiles[0]);
    } ,
  });

  return (
    <Center {...getRootProps()} borderWidth="1px" borderRadius="lg" px="6" py="4" {...oProps}>
      <input {...getInputProps()} />
      <VStack spacing="3">
        <Square size="10" bg="gray.100" borderRadius="lg">
          <Icon as={FiUploadCloud} boxSize="5" color="fg.muted" />
        </Square>
        <VStack spacing="1">
          <HStack spacing="2" align={'center'} justify={'center'} whiteSpace="nowrap">
            <Button variant="text" p={0} color="blue" size="sm">
              Click to upload
            </Button>
            <Text textStyle="sm" color="fg.muted">
              or drag and drop
            </Text>
          </HStack>
          <Text textStyle="xs" color="fg.muted">
            PNG, JPG or GIF up to 2MB
          </Text>
        </VStack>
      </VStack>
    </Center>
  );
};