
import { auth, googleProvider, facebookProvider, signInWithPopup } from './firebase';
import { useToast } from "@chakra-ui/react";


export const useSocialMediaAuth = () => {
   const toast = useToast();

   const handleSocialMediaSignUp = async (provider, providerName) => {
      try {
         await signInWithPopup(auth, provider);
         toast({
            title: "Logged In.",
            description: `You're now logged in with ${providerName}.`,
            status: "success",
            duration: 9000,
            isClosable: true,
            position: 'top',
         });
      } catch (error) {
         let errorMessage;
         switch (error.code) {
            case 'auth/popup-closed-by-user':
               errorMessage = 'The sign-in popup was closed before completing the process.';
               break;
            case 'auth/network-request-failed':
               errorMessage = 'A network error occurred. Please check your internet connection and try again.';
               break;
            default:
               errorMessage = error.message;
               break;
         }
         toast({
            title: "An error occurred.",
            description: errorMessage,
            status: "error",
            duration: 9000,
            isClosable: true,
         });
      }
   }

   const handleGoogleSignUp = async () => {
      await handleSocialMediaSignUp(googleProvider, "Google");
   }

   const handleFacebookSignUp = async () => {
      await handleSocialMediaSignUp(facebookProvider, "Facebook");
   }


   return { handleGoogleSignUp, handleFacebookSignUp };

}