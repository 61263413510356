import React, { useState } from 'react';
import {
  HStack,
  Icon,
  IconButton,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Stack,
  Link,
  Button,
  Spinner,
  Td,
  Tag,
  SkeletonText,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react'
import { FiEdit2, FiTrash2, FiArrowDown, FiArrowUp } from 'react-icons/fi';
import { updateDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';

export function ArticlesTable({ articles, loadingArticles, fetchArticles, sort, setSort, ...restProps }) {


  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [deleteArticleId, setDeleteArticleId] = useState(null);
  const [deletingArticleId, setDeletingArticleId] = useState(null);

  const handleDeleteConfirmation = (articleId) => {
    setDeleteConfirmation(true);
    setDeleteArticleId(articleId);
  };


  const handleSort = (field) => {
    let direction = 'asc';
    if (sort.field === field && sort.direction === 'asc') {
      direction = 'desc';
    }
    setSort({ field, direction });
  };


  const handleDeleteArticle = async () => {

    try {
      console.log('Deleting article:', deleteArticleId);
      setDeletingArticleId(deleteArticleId);

      // Update the article's deleted property to true
      const articleRef = doc(db, 'Articles', deleteArticleId);
      await updateDoc(articleRef, {
        deleted: true
      });

      await fetchArticles();
    } catch (error) {
      console.error('Error deleting article:', error);
    } finally {
      setDeleteConfirmation(false);
      setDeleteArticleId(null);
      setDeletingArticleId(null);
    }
  };

  return (
    <>
      <Table {...restProps}>
        <Thead>
          <Tr>
            <Th width="100%">
              <Link onClick={() => handleSort('title')}>
                <HStack spacing="1">
                  <Text>Title</Text>
                  {sort.field === 'title' && (
                    <Icon
                      as={sort.direction === 'asc' ? FiArrowUp : FiArrowDown}
                      color="gray.500"
                      boxSize="4"
                    />
                  )}
                </HStack>
              </Link>
            </Th>
            <Th minWidth="135px" textAlign="center">
              <Link onClick={() => handleSort('finishedAt')}>
                <HStack justify="center" spacing="1" >
                  <Text>Date</Text>
                  {sort.field === 'finishedAt' && (
                    <Icon
                      as={sort.direction === 'asc' ? FiArrowUp : FiArrowDown}
                      color="gray.500"
                      boxSize="4"
                    />
                  )}
                </HStack>
              </Link>
            </Th>
            <Th minWidth="140px"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {(!loadingArticles && articles.length > 0) && (
            <>
              {articles.map((article, id) => (
                <Tr key={article.id}>
                  <Td>
                    <Stack spacing="0.5" fontSize="sm">
                      <Text color="gray.800" fontWeight="bold">
                        <Link href={`/articles/${article.id}`}>{article.title}</Link>
                      </Text>
                      <Text color="gray.500">
                        {article.description.split(' ').slice(0, 50).join(' ')}
                        {article.description.split(' ').length > 50 ? '...' : ''}
                      </Text>
                    </Stack>
                  </Td>
                  <Td textAlign="center" justify="center">
                    <Tag bg="gray.600" size="sm" textColor="gray.100" alignSelf="center" justifySelf="center" textAlign="center" m={0}>
                      {article.finishedAt.toDate().toLocaleDateString()}
                    </Tag>
                  </Td>
                  <Td>
                    <Stack direction={{ base: 'column', sm: 'row' }} spacing={{ base: '0', sm: '1' }}>
                      <IconButton as="a" title="Edit Article" href={`/articles/${article.id}`} icon={<FiEdit2 />} variant="tertiary" aria-label="Edit Article" />
                      <Button title="Delete Article" variant="tertiary" aria-label="Delete Article" onClick={() => handleDeleteConfirmation(article.id)}>
                        {deletingArticleId === article.id ? <Spinner size="sm" /> : <FiTrash2 />}
                      </Button>
                    </Stack>
                  </Td>
                </Tr>
              ))}
            </>
          )}



        </Tbody>
      </Table >
      {(!loadingArticles && articles.length === 0) && (
        <Text my={5} align="center" color="gray.800" fontWeight="bold">
          No articles yet.
        </Text>
      )
      }

      {
        loadingArticles && (
          <Stack>
            <SkeletonText p={5} isLoaded={!loadingArticles} noOfLines={3} spacing="5" />
            <SkeletonText p={5} isLoaded={!loadingArticles} noOfLines={3} spacing="5" />
            <SkeletonText p={5} isLoaded={!loadingArticles} noOfLines={3} spacing="5" />
            <SkeletonText p={5} isLoaded={!loadingArticles} noOfLines={3} spacing="5" />
            <SkeletonText p={5} isLoaded={!loadingArticles} noOfLines={3} spacing="5" />
          </Stack>
        )
      }

      <AlertDialog
        isOpen={deleteConfirmation}
        isCentered
        leastDestructiveRef={null}
        onClose={() => setDeleteConfirmation(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Article
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this article?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={() => setDeleteConfirmation(false)}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDeleteArticle} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
