import React, { useEffect, useState } from 'react';
import {
   Stack,
   StackDivider,
   Text,
   Container,
   Box,
   Divider,
   HStack,
   Link,
   AbsoluteCenter,
   Icon,
   Button,
   useDisclosure,
} from '@chakra-ui/react';
import { TopNavbar } from '../shared/TopNavbar';
import { useNavigate } from 'react-router-dom';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import { ToolsSkeleton } from './ToolsSkeleton';
import { ToolRow } from './ToolRow';
import * as FaIcons from 'react-icons/fa';
import categories from './categories';
import { SuggestModalForm } from './SuggestModalForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBrainCircuit } from '@fortawesome/pro-light-svg-icons'



const Tools = () => {
   const navigate = useNavigate();
   const [user, loading] = useAuthState(auth);
   const [tools, setTools] = useState([]);
   const [loadingTools, setLoadingTools] = useState(true);
   const [uniqueCategories, setUniqueCategories] = useState([]);
   const loadingRows = [1, 2, 3, 4, 5, 6, 7, 8, 9];
   const { isOpen, onOpen, onClose } = useDisclosure();

   useEffect(() => {
      document.title = "Tools | AI Empowerment";
   }, []);


   useEffect(() => {
      if (loading) return;
      if (!user) return navigate("/login");
      if (user) fetchTools();
   }, [user, loading, navigate]);

   const fetchTools = async () => {
      try {
         const articlesRef = collection(db, 'Tools');
         const q = query(articlesRef, orderBy('createdAt', 'desc'));

         const querySnapshot = await getDocs(q);
         const fetchedTools = querySnapshot.docs.map((doc) => {
            const data = doc.data();
            data.id = doc.id; // Add the .id attribute to each article
            data.category = data.isAIEmpowerment ? 'ai-empowerment' : data.category;
            return data;
         });
         setTools(fetchedTools);
         setLoadingTools(false);

         // Extract unique categories and sort alphabetically
         setUniqueCategories(Array.from(new Set(fetchedTools.map(tool => tool.category))).sort());

      } catch (error) {
         console.error('Error fetching articles:', error);
         setLoadingTools(false);
      }
   };

   const IconComponent = ({ icon, ...otherProps }) => {
      return icon;
   };

   const ToolsForCategory = ({ category }) => {
      let categoryTools = tools.filter(tool => tool.category === category);
      categoryTools.sort((a, b) => a.title.localeCompare(b.title));
      return categoryTools.map((tool, id) => (
         <React.Fragment key={tool.id}>
            <ToolRow isAdmin={user.claims.admin} key={id} tool={tool} id={id}></ToolRow>
            {id !== categoryTools.length - 1 && <Divider />}
         </React.Fragment>
      ))
   }



   return (
      <Box bg={'gray.50'} minH="100vh">
         <TopNavbar user={user} loading={loading} />


         <SuggestModalForm isOpen={isOpen} onClose={onClose} user={user} />

         <Box
            as="section"
            py={{
               base: '4',
               md: '8',
            }}
         >
            <Container maxW="6xl">
               <Box
                  bg="white"
                  boxShadow="sm"
                  borderRadius="lg"
               >
                  <Stack spacing="5" >
                     <Stack p={6} justify="space-between" spacing="5">
                        <Stack spacing="3">
                           <HStack>
                              <Text flex={1} fontSize="xl" fontWeight="bold">
                                 AI Empowerment Curated AI Tools
                              </Text>
                              <Button onClick={onOpen} justifySelf="flex-end" size="xs" colorScheme="purple" fontWeight="bold">Suggest a tool</Button>
                           </HStack>
                           <Text color="gray.600">
                              A curated list of the best AI tools and resources.
                           </Text>
                           <Text color="gray.500" fontSize="sm">
                              Every tool is hand-picked and reviewed by our team of AI experts. We know how hard it is to find the right AI tool for your business, so we've done the hard work for you. Below is a list of the best AI tools and resources for SEO, blogging, content marketing, and more.
                           </Text>

                        </Stack>
                     </Stack>

                     {uniqueCategories.map((category, id) => {
                        category = categories.find(cat => cat.handle === category);
                        return (
                           <Stack key={id}>
                              <Stack p={4} px={6}>
                                 <Stack py={4} px={6} borderRadius="lg" bg="gray.200" bgGradient={category.handle === 'ai-empowerment' ? category.color : ''} spacing="0.3" flex={1}>
                                    <HStack>
                                       <IconComponent color={category.handle === "ai-empowerment" ? "white" : "gray.500"} icon={category.icon} />
                                       <Text fontSize="2xl" color={category.handle === "ai-empowerment" ? "white" : "gray.500"} fontWeight="bold">
                                          {category.title}
                                       </Text>
                                    </HStack>
                                    <Text color={category.handle === "ai-empowerment" ? "whiteAlpha.800" : "gray.500"} fontSize="sm">
                                       {category.description}
                                    </Text>
                                 </Stack>
                              </Stack>

                              <Stack p={6} spacing="5">
                                 <ToolsForCategory category={category.handle} />
                              </Stack>
                              {category.handle === "ai-empowerment" &&
                                 <Box position='relative' padding='10'>
                                    <Divider />
                                    <AbsoluteCenter bg='white' px='4'>
                                       <Text color="gray.600" fontWeight="bold" fontSize="sm">Third Party Tools</Text>
                                    </AbsoluteCenter>
                                 </Box>
           

                              }
                           </Stack>
                        )
                     })}

                     {loadingTools && loadingRows.map((tool, id) => (
                        <ToolsSkeleton p={6} key={id}></ToolsSkeleton>
                     ))}
                  </Stack>
               </Box>
            </Container>
         </Box>
      </Box>
   );
};

export default Tools;