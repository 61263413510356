import React, { useEffect, useState } from 'react';
import {auth} from './firebase.js';
import { getIdToken } from "firebase/auth";
import AuthContext from './AuthContext.js';

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if(user){

        user.getIdTokenResult().then((idTokenResult) => {
          user.claims = idTokenResult.claims || {};
          user.token = idTokenResult.token;
        });
      }
      setCurrentUser(user);
    });
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;