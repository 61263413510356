import React, { useEffect, useState } from 'react';
import {
   Box,
   Button,
   ButtonGroup,
   Container,
   HStack,
   Icon,
   Input,
   InputGroup,
   InputLeftElement,
   Stack,
   Text,
   useBreakpointValue,
   IconButton,
   Link,
   InputRightElement,
   CloseButton,
} from '@chakra-ui/react'
import { FaPlus, FaChevronRight, FaChevronLeft } from "react-icons/fa";
import { TopNavbar } from '../shared/TopNavbar';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, orderBy, limit, getDocs, startAfter } from 'firebase/firestore';
import { FiSearch } from 'react-icons/fi'
import debounce from 'lodash.debounce';
import { ArticlesTable } from './ArticlesTable'


const Articles = () => {
   const [user, loading] = useAuthState(auth);
   const [articles, setArticles] = useState([]);
   const [loadingArticles, setLoadingArticles] = useState(true);
   const [totalArticles, setTotalArticles] = useState(0);
   const [currentPage, setCurrentPage] = useState(1);
   const [lastDoc, setLastDoc] = useState(null);
   const [sort, setSort] = useState({ 'field': 'finishedAt', 'direction': 'desc' });
   const [totalPages, setTotalPages] = useState(0);
   const [searchInput, setSearchInput] = useState(''); 
   const [firstDocs, setFirstDocs] = useState([]);

   const pageSize = 30;

   const navigate = useNavigate();
   const isMobile = useBreakpointValue({
      base: true,
      md: false,
   })

   const fetchArticles = React.useCallback(async (startAfterDoc = null, searchTerm = '') => {
      console.log('Fetching articles...');
      try {
         setLoadingArticles(true);
         const user = auth.currentUser;
         const articlesRef = collection(db, 'Articles');
         let q = query(articlesRef, where('userId', '==', user.uid), where('deleted', '==', false), orderBy(sort.field, sort.direction), limit(pageSize));

         if (startAfterDoc) {
            q = query(q, startAfter(startAfterDoc));
         }

         if (searchTerm && searchTerm !== '') {
            const searchWords = searchTerm.toLowerCase().split(' ');
            q = query(q, where('keywords', 'array-contains-any', searchWords));
         }

         const querySnapshot = await getDocs(q);

         if (querySnapshot.docs.length > 0) {
            setFirstDocs(prevFirstDocs => [...prevFirstDocs, querySnapshot.docs[0]]);
         }

         const fetchedArticles = querySnapshot.docs.map((doc) => {
            const data = doc.data();
            data.id = doc.id; // Add the .id attribute to each article
            return data;
         });

         setArticles(fetchedArticles);

         // Save the last document from the query
         if (querySnapshot.docs.length > 0) {
            setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
         }
      } catch (error) {
         console.error('Error fetching articles:', error);
      } finally {
         setLoadingArticles(false);
      }
   }, [sort, currentPage]);

   const fetchNextPage = () => {
      if (lastDoc) {
         setCurrentPage(currentPage + 1);
         fetchArticles(lastDoc);
      }
   };

   const fetchPreviousPage = () => {
      if (currentPage > 1) {
         const previousPage = currentPage - 1;
         setCurrentPage(previousPage);
         const endBeforeDoc = firstDocs[previousPage - 2]; // -2 because array is 0-indexed
         fetchArticles(endBeforeDoc);
      }
   };

   useEffect(() => {
      document.title = "My Articles | AI Empowerment";
   }, []);

   useEffect(() => {
      const fetchTotalArticles = async () => {
         const user = auth.currentUser;
         const articlesRef = collection(db, 'Articles');
         const q = query(articlesRef, where('userId', '==', user.uid), where('deleted', '==', false));

         const querySnapshot = await getDocs(q);
         setTotalArticles(querySnapshot.size);
         console.log('total pages', Math.ceil(querySnapshot.size / pageSize));
         setTotalPages(Math.ceil(querySnapshot.size / pageSize));
      };

      if (user) fetchTotalArticles();
   }, [user]);

   const debouncedSearch = React.useCallback(
      debounce((newState) => {
         console.log('Searching for:', newState)
         fetchArticles(null, newState);
      }, 500),
      [fetchArticles]
   );

   useEffect(() => {
      if (!user) return;
      debouncedSearch(searchInput);
   }, [searchInput]);


   useEffect(() => {
      if (loading) return;
      if (user) fetchArticles();
      if (!user) return navigate("/login");
   }, [user, loading, sort]);


   return (
      <Box bg={'gray.50'} minH="100vh">
         <TopNavbar user={user} loading={loading} />

         <Box
            as="section"
            py={{
               base: '4',
               md: '8',
            }}
         >
            <Container maxW="6xl">
               <Box
                  bg="white"
                  boxShadow={{
                     base: 'none',
                     md: 'sm',
                  }}
                  borderRadius={{
                     base: 'none',
                     md: 'lg',
                  }}
               >
                  <Stack spacing="5">
                     <Box
                        px={{
                           base: '4',
                           md: '6',
                        }}
                        pt="5"
                     >
                        <Stack
                           direction={{
                              base: 'column',
                              md: 'row',
                           }}
                           justify="space-between"
                        >
                           <HStack spacing={3}>
                              <Text fontSize="xl" fontWeight="bold">
                                 My Articles
                              </Text>
                              <IconButton
                                 as={Link}
                                 aria-label="Add new"
                                 icon={<FaPlus />}
                                 target="_blank"
                                 href="https://chat.openai.com/g/g-DA2kvvY7Y-alice-the-seo-blogging-wizard"
                                 size="xs"
                                 colorScheme="purple"
                              />
                           </HStack>
                           <InputGroup maxW="xs">
                              <InputLeftElement pointerEvents="none">
                                 <Icon as={FiSearch} color="gray.500" boxSize="5" />
                              </InputLeftElement>
                              <Input
                                 placeholder="Search"
                                 value={searchInput}
                                 onChange={e => setSearchInput(e.target.value)}
                                 paddingRight="2.5rem"
                              />
                              {searchInput !== '' && (
                                 <InputRightElement>
                                    <CloseButton size='sm' onClick={() => setSearchInput('')} />
                                 </InputRightElement>
                              )}

                           </InputGroup>
                        </Stack>
                     </Box>
                     <Box overflowX="auto">
                        <ArticlesTable articles={articles} loadingArticles={loadingArticles} fetchArticles={fetchArticles} sort={sort} setSort={setSort} />
                     </Box>
                     <Box
                        px={{
                           base: '4',
                           md: '6',
                        }}
                        pb="5"
                     >
                        {(!loadingArticles && totalArticles > 0 && searchInput === '') && (
                           <HStack spacing="3" justify="space-between">
                              {!isMobile && (
                                 <Text fontSize="sm" color="gray.500" >
                                    Showing {((currentPage - 1) * pageSize) + 1} to {Math.min(totalArticles, (currentPage * pageSize))} of {totalArticles} results
                                 </Text>
                              )}
                              <ButtonGroup
                                 spacing="3"
                                 justifyContent="space-between"
                                 width={{
                                    base: 'full',
                                    md: 'auto',
                                 }}
                                 variant="secondary"
                              >
                                 <Button
                                    size="sm"
                                    isDisabled={currentPage === 1}
                                    color="gray.600"
                                    leftIcon={<FaChevronLeft />}
                                    onClick={fetchPreviousPage}
                                    variant="outline">
                                    Previous
                                 </Button>
                                 <Button
                                    size="sm"
                                    isDisabled={currentPage === totalPages}
                                    onClick={fetchNextPage}
                                    color="gray.600"
                                    rightIcon={<FaChevronRight />}
                                    variant="outline">
                                    Next
                                 </Button>
                              </ButtonGroup>
                           </HStack>
                        )}
                     </Box>
                  </Stack>
               </Box>
            </Container>
         </Box>
      </Box>
   );
};

export default Articles;