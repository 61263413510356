import { Button, Drawer, DrawerBody, DrawerContent, Stack, Link, Avatar, ButtonGroup } from '@chakra-ui/react'

export const MobileDrawer = ({ currentUser, ...otherProps }) => (
  <Drawer placement="top" {...otherProps}>
    <DrawerContent>
      <DrawerBody mt="16">
        <Stack spacing="6" align="stretch">
          { (currentUser)? (
            <>
            <Button size="lg" variant="text" colorScheme="gray" as={Link} href="/articles" key={1}>My Articles</Button>
            <Button size="lg" variant="text" colorScheme="gray" as={Link} href="/tools" key={2}>Tools</Button>
            <Button size="lg" variant="text" colorScheme="gray" as={Link} href="/contact" key={3}>Contact</Button>
            </>
          ): (
            <>
            <Button size="lg" variant="text" colorScheme="gray" as={Link} href="http://aiempowerment.ai" key={1}>About</Button>
            <Button size="lg" variant="text" colorScheme="gray" as={Link} href="/contact" key={2}>Contact</Button>
            </>
          )}
          {currentUser ? (
            <Link mx="auto" href='/account' >
              <Avatar bg="purple" name={currentUser.email} src={currentUser.photoURL} />
            </Link>
          ) : (
            <Button as={Link} href='/signup' colorScheme="purple">Log in</Button>
          )}
        </Stack>
      </DrawerBody>
    </DrawerContent>
  </Drawer>
)
