import { 
   Box, 
   Container,
   useToast,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { TopNavbar } from '../shared/TopNavbar';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import AddToolsForm from "./AddToolsForm";
import { auth, functions } from '../../firebase';
import { httpsCallable } from "firebase/functions";


export default function Admin() {
   const [user, loading] = useAuthState(auth);
   const navigate = useNavigate();

   useEffect(() => {
      document.title = "Admin | AI Empowerment";

		// const addAdminRole = httpsCallable(functions, 'addAdminRole');
		// addAdminRole()
		// 	.then(result => {
		// 		console.log(result.data.message);
		// 	})
		// 	.catch(error => {
		// 		console.error(`Error: ${error.message}`);
		// 	});
    }, []);


   useEffect(() => {
      if (loading) return;
      if (!user) return navigate("/login");
      if(user && !user.claims.admin) return navigate("/");
   }, [user, loading, navigate]);

   return (
      <Box bg={'gray.50'} minH="100vh">
         <TopNavbar user={user} loading={loading} />
         <Box
            as="section"
            py={{
               base: '4',
               md: '8',
            }}
         >
            <Container maxW="6xl">
               <Box
                  bg="white"
                  boxShadow="sm"
                  borderRadius="lg"
                  p={{
                     base: '4',
                     md: '6',
                  }}
               >
                  <AddToolsForm />
               </Box>

            </Container>
         </Box>
      </Box>
   );
}