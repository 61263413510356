import {
  Box,
  HStack,
  Icon,
  Stack,
  Link,
  Text,
  Button,
  Skeleton,
  Divider,
  SkeletonText,
  SkeletonCircle,
} from '@chakra-ui/react'
import {
  FiList,
  FiHome,
  FiLogOut,
} from 'react-icons/fi'
import { GoCalendar } from 'react-icons/go'
import { Card } from './Card'
import { CardContent } from './CardContent'
import { CardHeader } from './CardHeader'
import { UserAvatar } from './UserAvatar'
import { logout } from '../../../firebase'
import { format } from 'date-fns';

export const UserCard = ({ user, loading }) => {
  let createdAt = new Date();
  if(user){
    createdAt = new Date(parseInt(user.metadata.createdAt));
  }
  return (
    <Box as="section" py="12">
      <Card>
        <Stack
          direction={{
            base: 'column',
            md: 'row',
          }}
          spacing={{
            base: '4',
            md: '10',
          }}
        >
          <SkeletonCircle size='8rem' isLoaded={!loading}>
            <UserAvatar
              name={user?.displayName}
              src={user?.photoURL}
            />
          </SkeletonCircle>
          <CardContent>
            <SkeletonText isLoaded={!loading} noOfLines={1} skeletonHeight='5' />
            <CardHeader
              title={user?.displayName}
              action={!loading && (
                <Button
                  onClick={logout}
                  size="sm"
                  color="red.500"
                  variant="tertiary"
                  leftIcon={<Icon as={FiLogOut} color="red.500" marginStart="-1" />}
                >
                  Logout
                </Button>
              )}
            />
            <Skeleton isLoaded={!loading}>
              <Stack spacing="1" mt="2">
                <HStack fontSize="sm">
                  <Icon as={GoCalendar} color="gray.500" />
                    <Text>{format(createdAt, 'MMMM dd, yyyy')}</Text>
                </HStack>
              </Stack>
            </Skeleton>

            <Divider my={5} />
            <Skeleton isLoaded={!loading}>
              <Stack direction="row" spacing="5" mt="2">
                <Button size="md" leftIcon={<FiList />} variant="outline" as={Link} href="/articles">
                  My Articles
                </Button>
                <Button size="md" leftIcon={<FiHome />} variant="outline" as={Link} href="/">
                  Member Home
                </Button>
              </Stack>
            </Skeleton>
          </CardContent>
        </Stack>
      </Card>
    </Box>
  )
}

