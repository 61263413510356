// src/App.js

import React from 'react';
import { extendTheme } from '@chakra-ui/react'
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Article from './components/Article';
import Articles from './components/Articles';
import Dashboard from './components/Dashboard'; // Import the Home component
import Login from './components/Login'; // Import the Login component
import Signup from './components/Signup'; // Import the Signup component
import Account from './components/Account';
import Admin from './components/Admin';
import Tools from './components/Tools';
import EditTool from './components/Admin/EditTool';
import AuthProvider from './AuthProvider';
import './App.css';
import '@fontsource-variable/open-sans'
import '@fontsource-variable/spline-sans'
import { theme } from './theme'

const proTheme = extendTheme(theme)
const extenstion = {
  colors: { ...proTheme.colors, brand: proTheme.colors.purple },
}
const myTheme = extendTheme(extenstion, proTheme)

function App() {
  return (
    <ChakraProvider theme={myTheme} >
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/account" element={<Account />} />
            <Route path="/articles/:id" element={<Article />} />
            <Route path="/articles" element={<Articles />} />
            <Route path="/tools" element={<Tools />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/admin/tools/edit/:toolId" element={<EditTool/>} />
          </Routes>
        </Router>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
