import React from 'react';
import {
   Button,
   Stack,
   StackDivider,
   Text,
   Link,
} from '@chakra-ui/react';
import { FiList, } from 'react-icons/fi';
import { useEffect, useState } from 'react';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import { ToolsSkeleton } from '../Tools/ToolsSkeleton';
import { ToolRow } from '../Tools/ToolRow';

export function LatestTools(props) {

   const [user, loading] = useAuthState(auth);
   const [tools, setTools] = useState([]);
   const [loadingTools, setLoadingTools] = useState(true);
   const loadingRows = [1, 2, 3];

   useEffect(() => {
      if (loading) return;
      if (user) fetchArticles();
   }, [user, loading]);

   const fetchArticles = async () => {
      try {
         const articlesRef = collection(db, 'Tools');
         const q = query(articlesRef, orderBy('createdAt', 'desc'), limit(3));

         const querySnapshot = await getDocs(q);
         const fetchedTools = querySnapshot.docs.map((doc) => {
            const data = doc.data();
            data.id = doc.id; // Add the .id attribute to each article
            return data;
         });

         setTools(fetchedTools);
         setLoadingTools(false);
      } catch (error) {
         console.error('Error fetching articles:', error);
         setLoadingTools(false);
      }
   };

   return (
      <Stack spacing="5" divider={<StackDivider />}>
         <Stack justify="space-between" direction={{ base: 'column', sm: 'row' }} spacing="5">
            <Stack spacing="1">
               <Text fontSize="xl" fontWeight="bold">
                  Latest Tools
               </Text>
               <Text textStyle="sm" color="gray.600">
                  A curated list of the best AI tools and resources for SEO, blogging, content marketing, and more.
               </Text>
            </Stack>
         </Stack>
         {tools.map((tool, id) => (
            <ToolRow key={id} tool={tool} id={id}></ToolRow>
         ))}

         {(!loadingTools && tools.length > 0) && (
            <Button size="sm" variant="ghost" as={Link} href="/tools" leftIcon={<FiList />}>
               View All Tools
            </Button>
         )}

         {loadingTools && loadingRows.map((tool, id) => (
            <ToolsSkeleton key={id}></ToolsSkeleton>
         ))}
      </Stack>
   )
}
