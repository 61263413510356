import {
  Box,
  Flex,
  Spinner,
  Center,
  Text,
} from '@chakra-ui/react'
import React, { useState, useEffect, Component } from 'react';
import ReactMarkdown from 'react-markdown';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from '../../firebase';
import { useParams } from 'react-router-dom';
import { Sidebar } from './Sidebar'
import { TopNavbar } from '../shared/TopNavbar'
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ReactDOMServer from 'react-dom/server';
import TurndownService from 'turndown';
import { marked } from 'marked';
import debounce from 'lodash.debounce';

import { AUTH_TOKEN } from '../../config';
import "./article.css"
import { loginWarning } from './loginWarning';



export const Article = () => {

  const [article, setArticle] = useState(null);
  const [articleNotFound, setArticleNotFound] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const { id } = useParams();
  const [wordCount, setWordCount] = useState(0);
  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    const contentState = editorState.getCurrentContent();
    const plainText = contentState.getPlainText('');
    const words = plainText.trim().split(/\s+/);
    setWordCount(words.length);
  }, [editorState]);


  useEffect(() => {
    document.title = "Article | AI Empowerment";
  }, []);

  useEffect(() => {
    if (loading) return;

    //if there is a user logged in, wer want to send their authorization to the api so we know its them, if anonymouse we use the default token
    let authToken = AUTH_TOKEN;
    if (user && user.token) {
      authToken = user.token;
    };

    fetch(`https://app.aiempowerment.ai/api/articles/${id}`, {
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    })
      .then(response => response.json())
      .then(data => {
        console.log('Article fetched successfully:', data);
        if (!data.articleContent) {
          console.log('Article not found');
          setArticleNotFound(true);
        } else {
          setArticleNotFound(false);
          const markdown = data.articleContent;
          const html = ReactDOMServer.renderToStaticMarkup(<ReactMarkdown>{markdown}</ReactMarkdown>);
          const contentBlock = htmlToDraft(html);
          const plaintext = markdownToPlainText(markdown);
          setArticle({ ...data, htmlContent: html, textContent: plaintext });
          document.title = data.title; // Set the document title to the article's title
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);
          }
        }
      })
      .catch(error => {
        setArticleNotFound(true);
        console.error('Error fetching article:', error)
      });

  }, [user, loading, id]);


  class WordCountLabel extends Component {
    render() {
      return (
        <Center>
          <Text px={4} m={0} mb="6px" fontWeight="bold" fontSize="xs" opacity={0.8}>{wordCount} words</Text>
        </Center>
      );
    }
  }

  const markdownToPlainText = (markdown) => {
    const html = marked(markdown);
    const text = html.replace(/<[^>]*>/g, '');
    return text.replace(/&nbsp;/g, ' ').replace(/&#39;/g, "'");
  };

  const saveChanges = React.useCallback((newState) => {
    const html = draftToHtml(convertToRaw(newState.getCurrentContent()));
    const turndownService = new TurndownService();
    const markdown = turndownService.turndown(html);
    setArticle({ ...article, articleContent: markdown, htmlContent: html });

    let authToken = AUTH_TOKEN;
    if (user && user.token) {
      authToken = user.token;
    };

    // Update the article in the database
    fetch(`https://app.aiempowerment.ai/api/articles/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`
      },
      body: JSON.stringify({ articleContent: markdown }),
      mode: 'cors',
    })
      .then(response => response.json())
      .then(data => {
        console.log('Article updated successfully:', data);
      })
      .catch(error => {
        console.error('Error updating article:', error);
      });
  }, [setArticle, article, id]);

  const debouncedSave = React.useCallback(
    debounce((newState) => {
      saveChanges(newState);
    }, 500),
    [saveChanges]
  );

  return (
    <Box bg={'gray.50'} minH="100vh">

      <TopNavbar user={user} loading={loading} />

      {articleNotFound ? (
        <Flex justify="center" align="center" height="100vh">
          <Box>
            <Box>Article not found.</Box>
          </Box>
        </Flex>
      ) : !article ? (
        <Flex justify="center" align="center" height="100vh">
          <Spinner />
        </Flex>
      ) : (
        <Box>
          <Box p={5} pt={1}>

            {(!user && !loading) && (
              loginWarning(article)
            )}

            <Flex borderRadius="9" opacity={0.7} boxShadow="lg" height={user ? "calc(100vh - 125px)" : "calc(100vh - 180px)"} border="1px solid #eee" overflow="hidden">
              <Box
                bg={'white'}
                flex="1"
                overflowY="auto"
              >
                <Text className="title-text" fontSize="2xl" fontWeight="bold" textAlign="center" my={3}>{article.title}</Text>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={(newState) => {
                    if (newState.getCurrentContent() !== editorState.getCurrentContent()) {
                      debouncedSave(newState);
                    }
                    setEditorState(newState);
                  }}
                  toolbar={{
                    options: ['inline', 'blockType', 'list', 'link', 'history'],
                    inline: {
                      options: ['bold', 'italic', 'underline', 'monospace'],
                    },
                    blockType: {
                      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
                    },
                    list: {
                      options: ['unordered', 'ordered'],
                    },
                    history: {
                      inDropdown: false,
                      options: ['undo', 'redo'],
                    },
                  }}
                  toolbarCustomButtons={[<WordCountLabel />]}
                />
              </Box>
              <Box
                height="full"
                width={"18rem"}
                display={{
                  base: 'initial',
                  lg: 'initial',
                }}
                overflowY="auto"
                borderRightWidth="1px"
                className="sidebar"
              >
                <Sidebar user={user} loading={loading} article={article} />
              </Box>
            </Flex>
          </Box>
        </Box>
      )}

    </Box>
  )
}
