import { useEffect } from "react";
import {
   Box,
   Button,
   FormControl,
   FormLabel,
   Input,
   Textarea,
   Select,
   Image,
   useToast,
   InputGroup,
   InputLeftAddon,
   FormHelperText,
   Flex,
   Divider,
   Text,
   Checkbox,
   Stack,
} from "@chakra-ui/react";
import { useState } from "react";
import { collection, addDoc, serverTimestamp, updateDoc, doc, getDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { Dropzone } from './Dropzone'
import { db } from "../../firebase";
import categories from "../Tools/categories";

export default function AddToolsForm({ toolId }) {
   const [tool, setTool] = useState(null);
   const [title, setTitle] = useState("");
   const [url, setUrl] = useState("");
   const [isAIEmpowerment, setIsAIEmpowerment] = useState(false); 
   const [description, setDescription] = useState("");
   const [category, setCategory] = useState("");
   const [photo, setPhoto] = useState(null);
   const [loading, setLoading] = useState(false);
   const toast = useToast();

   useEffect(() => {
      if (toolId) {
         const fetchTool = async () => {
            const toolDoc = await getDoc(doc(db, "Tools", toolId));
            if (toolDoc.exists()) {
               setTool({ id: toolId, ...toolDoc.data() });
            } else {
               console.error("No such document!");
            }
         };

         fetchTool();
      }
   }, [toolId]);

   useEffect(() => {
      if (tool) {
         setTitle(tool.title);
         setUrl(tool.url);
         setDescription(tool.description);
         setCategory(tool.category);
         setIsAIEmpowerment(tool.isAIEmpowerment || false);
      }
   }, [tool]);

   const handlePhotoChange = (file) => {
      if (file) {
         setPhoto(file);
      }
   };

   const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);

      try {
         // Add the new tool to Firestore
         let id;

         if (!tool) {
            console.log('creating new tool');
            const docRef = await addDoc(collection(db, "Tools"), {
               title,
               url: url.replace('https://', '').replace('http://', ''),
               description,
               category,
               isAIEmpowerment,
               createdAt: serverTimestamp(),
            });
            id = docRef.id;
         } else {
            console.log('updating tool');
            await updateDoc(doc(db, "Tools", tool.id), {
               title,
               url: url.replace('https://', '').replace('http://', ''),
               description,
               category,
               isAIEmpowerment,
               createdAt: serverTimestamp(),
            });
            id = tool.id;
         }

         // Upload the photo to Firebase Storage
         if (photo) {
            const storage = getStorage();
            const storageRef = ref(storage, `toolLogos/${id}.png`);
            const uploadTask = uploadBytesResumable(storageRef, photo);
            //img is made public in firebase callback
            uploadTask.on('state_changed',
               (snapshot) => {
                  // Handle the upload progress
                  console.log(snapshot);
               },
               (error) => {
                  // Handle unsuccessful uploads
                  console.error("Error uploading image: ", error);
                  setLoading(false);
               },
               async () => {
                  // Handle successful uploads on complete
                  await cleanUp(id);
               }
            );
         }else{
            await cleanUp(id);
         }
      } catch (error) {
         console.log(error);
         setLoading(false);
      }
   };

   async function cleanUp(id) {
      toast({
         title: "Tool added.",
         description: `We've added your tool ${id} to the database.`,
         status: "success",
         position: "top",
         duration: 5000,
         isClosable: true,
      });

      await updateDoc(doc(db, "Tools", id), {
         photoUrl: `https://firebasestorage.googleapis.com/ai-empowerment.appspot.com/toolLogos/${id}.png`
      });

      if(!tool){
         setTitle("");
         setUrl("");
         setDescription("");
         setCategory("");
         setPhoto(null);
      }

      setLoading(false);
   }

   return (
      <Box bg="bg.surface" boxShadow="sm" borderRadius="lg" flex="1" >
         <Text textAlign="center" fontWeight='medium' color='gray.600' fontSize="2xl">{!tool ? 'Add new tool' : 'Edit Tool'}</Text>
         <form onSubmit={handleSubmit}>
            <Stack
               spacing="6"
               px={{
                  base: '4',
                  md: '6',
               }}
               py={{
                  base: '5',
                  md: '6',
               }}
            >
               <FormControl id="title">
                  <FormLabel>Title</FormLabel>
                  <Input type="text" required value={title} onChange={(e) => setTitle(e.target.value)} />
               </FormControl>
               <FormControl id="url">
                  <FormLabel>Website</FormLabel>
                  <InputGroup>
                     <InputLeftAddon>https://</InputLeftAddon>
                     <Input type="text" required value={url} onChange={(e) => setUrl(e.target.value)} />
                  </InputGroup>
               </FormControl>
               <FormControl id="description">
                  <FormLabel>Description</FormLabel>
                  <Textarea rows={3} required resize="none" value={description} onChange={(e) => setDescription(e.target.value)} />
                  <FormHelperText color="fg.subtle">Write a short description about the tool or service</FormHelperText>
               </FormControl>
               <FormControl id="category">
                  <FormLabel>Category</FormLabel>
                  <Select required placeholder="Select category" value={category} onChange={(e) => setCategory(e.target.value)}>
                     {categories.map((category, index) => (
                        <option key={index} value={category.handle}>{category.title}</option>
                     ))}
                  </Select>
               </FormControl>
               <FormControl id="isAIEmpowerment">
                  <FormLabel>AI Empowerment App?</FormLabel>
                  <Checkbox
                     isChecked={isAIEmpowerment}
                     onChange={(e) => setIsAIEmpowerment(e.target.checked)}
                  >
                    isAiEmpowerment
                  </Checkbox>
               </FormControl>
               <FormControl id="picture">
                  <FormLabel>Logo</FormLabel>
                  <Stack
                     spacing={{
                        base: '3',
                        md: '5',
                     }}
                     direction={{
                        base: 'column',
                        sm: 'row',
                     }}
                  >
                     <Image
                        boxSize="150px"
                        borderRadius="6px"
                        fallbackSrc='https://via.placeholder.com/150'
                        src={photo && URL.createObjectURL(photo)}
                     />
                     <Dropzone width="full" onChange={handlePhotoChange} />
                  </Stack>
               </FormControl>
            </Stack>
            <Divider />
            <Flex
               direction="row-reverse"
               py="4"
               px={{
                  base: '4',
                  md: '6',
               }}
            >
               <Button isLoading={loading} type="submit">Save</Button>
            </Flex>
         </form>
      </Box>
   );
}


