import React, { useEffect } from 'react';
import {
  Box,
  Button,
  IconButton,
  Flex,
  HStack,
  Icon,
  Link,
  Stack,
  Text,
  useToast,
  useColorModeValue as mode,
  Divider,
  Skeleton,
} from '@chakra-ui/react'
import {
  FiArrowUpRight,
  FiCopy,
  FiCode,
  FiFileText,
  FiList,
} from 'react-icons/fi'

import { LiaMarkdown } from "react-icons/lia";
import { useState } from 'react';
import { ColumnHeader } from '../shared/Column'

export const Sidebar = ({ article, user, loading, ...otherProps }) => {


  const toast = useToast();

  const [toastMessage, setToastMessage] = useState(null);

  const copyToClipboard = async (data, message) => {
    try {
      await navigator.clipboard.writeText(data);
      setToastMessage(message);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  useEffect(() => {
    if (toastMessage) {
      toast({
        title: "Copied",
        description: toastMessage,
        status: "success",
        duration: 2000,
        isClosable: true,
        onCloseComplete: () => setToastMessage(null),
        position: 'top',

      });
    }
  }, [toastMessage, toast]);


  return (
    <Flex bg="gray.800"  height="full" direction="column" justify="space-between" {...otherProps}>
      <Stack bg="gray.800" spacing="3">
        <ColumnHeader>
          <Text m={0} fontWeight="bold" fontSize="sm" lineHeight="1.25rem">
            Article Editor
          </Text>
        </ColumnHeader>

        <Stack px="3" spacing="6">
        <Stack spacing="3">
            <HStack alignItems="center" justifyContent="space-between">
              <NavHeading m={0} >Title</NavHeading>
              <IconButton
                size="xs"
                aria-label='Copy Title'
                colorScheme="purple"
                icon={<FiCopy />}
                onClick={() => copyToClipboard(article.title, 'Ttile Copied!')}
              />
            </HStack>
            <Box p={3} borderRadius={6} background={"gray.700"}>
              <Text m={0} color="gray.100" fontSize="xs" opacity={0.6}>{article.title}</Text>
            </Box>
          </Stack>
          <Stack spacing="3">
            <HStack alignItems="center" justifyContent="space-between">
              <NavHeading m={0} >URL Slug</NavHeading>
              <IconButton
                size="xs"
                aria-label='Copy URL Slug'
                colorScheme="purple"
                icon={<FiCopy />}
                onClick={() => copyToClipboard(article.slug, 'URL Slug Copied!')}
              />
            </HStack>
            <Box p={3} borderRadius={6} background={"gray.700"}>
              <Text m={0} color="gray.100" fontSize="xs" opacity={0.6}>/{article.slug}</Text>
            </Box>
          </Stack>
          <Stack spacing="3">
            <HStack align="center" justifyContent="space-between">
              <NavHeading m={0} >Meta Description</NavHeading>
              <IconButton
                size="xs"
                colorScheme="purple"
                aria-label='Copy URL Slug'
                icon={<FiCopy />}
                onClick={() => copyToClipboard(article.description, 'Meta Description Copied!')}
              />
            </HStack>
            <Box p={3} borderRadius={6} background={"gray.700"}>
              <Text m={0} color="gray.100" fontSize="xs" opacity={0.6}>{article.description}</Text>
            </Box>
          </Stack>
          <Stack spacing="3">
            <NavHeading m={0} >Created</NavHeading>
            <Box p={3} borderRadius={6} background={"gray.700"}>
              <Text m={0} color="gray.100" fontSize="xs" opacity={0.6}>
                {new Date(article.createdAt._seconds * 1000).toLocaleDateString("en-US")}
              </Text>
            </Box>
          </Stack>
          <Stack spacing="3">
            <NavHeading>Article Content</NavHeading>
            <Stack spacing="3">
              <NavLink icon={LiaMarkdown} onClick={() => copyToClipboard(article.articleContent, 'Markdown Copied!')}>
                Copy Markdown
              </NavLink>
              <NavLink icon={FiCode} onClick={() => copyToClipboard(article.htmlContent, 'HTML Copied!')}>
                Copy HTML
              </NavLink>
              <NavLink icon={FiFileText} onClick={() => copyToClipboard(article.textContent, 'Text Copied!')}>
                Copy Text
              </NavLink>
            </Stack>
          </Stack>
          <Stack spacing="3">
            <Divider borderColor="gray.700" my={3} />
            <Text color="gray.100" fontSize="xs" opacity={0.8}>Thank you for trying our article generator! Make sure to download the image from GPT as we don't have access to the download URL. We also recommend further editing with <Link fontWeight="bold" color="white" href="https://www.grammarly.com/" isExternal >Grammarly  </Link> to enhance the quality of your content. Grammarly is an AI-powered writing assistant that helps you improve grammar, spelling, and style.</Text>
          </Stack>
        </Stack>
      </Stack>
      <Box bg="gray.800"  borderColor="gray.700" borderTopWidth="1px">
        {user ? (
          <NavButton leftIcon={<FiList />} as={Link} href="/articles">
            My Articles
          </NavButton>
        ) : (
          <Skeleton isLoaded={!loading}>
            <NavButton>Sign In</NavButton>
          </Skeleton>
        )}
      </Box>
    </Flex>
  )
}
const NavButton = (props) => (
  <Button
    width="full"
    borderRadius="0"
    variant="tertiary"
    size="lg"
    color="gray.200"
    fontSize="sm"
    _hover={{
      bg: 'gray.200',
      color: 'black'

    }}
    _active={{
      bg: 'gray.200',
      color: 'black'
    }}
    _focus={{
      boxShadow: 'none',
    }}
    _focusVisible={{
      boxShadow: 'outline',
    }}
    {...props}
  />
)
export const NavLink = (props) => {
  const { icon, ...linkProps } = props
  return (
    <Link
      px="2"
      py="1.5"
      color="white"
      borderRadius="md"
      _hover={{
        bg: 'gray.700',
        color: 'white',
      }}
      _activeLink={{
        bg: 'gray.700',
        color: 'white',
      }}
      {...linkProps}
    >
      <HStack justify="space-between">
        <HStack spacing="3">
          <Icon as={icon} />
          <Text as="span" fontSize="sm" lineHeight="1.25rem">
            {props.children}
          </Text>
        </HStack>
        {props.isExternal && (
          <Icon as={FiArrowUpRight} boxSize="4" color={mode('gray.600', 'gray.400')} />
        )}
      </HStack>
    </Link>
  )
}
export const NavHeading = (props) => (
  <Text
    as="h4"
    fontSize="xs"
    fontWeight="bold"
    px="2"
    lineHeight="1.25"
    color={"gray.200"}
    {...props}
  />
)
