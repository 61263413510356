import React, { useState } from 'react';
import {
   Button,
   InputGroup,
   Textarea,
   FormHelperText,
   InputLeftAddon,
   Modal,
   ModalOverlay,
   ModalContent,
   ModalHeader,
   ModalCloseButton,
   ModalBody,
   ModalFooter,
   FormControl,
   FormLabel,
   Input,
   useToast,
} from "@chakra-ui/react";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase"; // Assuming you have a firebase.js file with the Firebase configuration

export function SuggestModalForm({ isOpen, onOpen, onClose, user }) {

   const initialRef = React.useRef();
   const [title, setTitle] = useState("");
   const [url, setUrl] = useState("");
   const [description, setDescription] = useState("");
   const [loading, setLoading] = useState(false);
   const toast = useToast();




   const createSuggestion = async (suggestion) => {
      try {
         suggestion.url = suggestion.url.replace(/(^\w+:|^)\/\//, '');
         suggestion.createdAt = serverTimestamp();
         suggestion.suggestedBy = user.uid;
         suggestion.suggestedByEmail = user.email;
         // Create a new suggestion in the database
         const docRef = await addDoc(collection(db, "Suggestions"), suggestion);
         return docRef;
      } catch (error) {
         console.error("Error adding document: ", error);
         throw new Error("An error occurred while saving the suggestion.");
      }
   };



   const handleSubmit = async (event) => {
      event.preventDefault();
      // Handle the form submission here

      // Show loading on the button while saving
      setLoading(true);

      try {
         // Create a new suggestion in the database
         await createSuggestion({ title, url, description });

         // Clear the form
         setTitle("");
         setUrl("");
         setDescription("");

         // Send a toast thanking the user
         toast({
            title: "Thank you!",
            description: "Your suggestion has been submitted.",
            status: "success",
            position: "top",
            duration: 5000,
            isClosable: true,
         });
      } catch (error) {
         // Handle error if the suggestion couldn't be saved
         toast({
            title: "Error",
            description: "An error occurred while saving your suggestion.",
            status: "error",
            position: "top",
            duration: 5000,
            isClosable: true,
         });
      } finally {
         // Hide loading on the button
         setLoading(false);
      }
   };

   return (
      <Modal initialFocusRef={initialRef} isCentered colorScheme="purple" isOpen={isOpen} onClose={onClose}>
         <ModalOverlay />
         <ModalContent>
            <ModalHeader color="purple.500">Suggest a Tool</ModalHeader>
            <ModalCloseButton />
            <ModalBody >
               <form onSubmit={handleSubmit}>
                  <FormControl my={4}>
                     <FormLabel color="gray.500" fontWeight="bold">Website</FormLabel>
                     <InputGroup>
                        <InputLeftAddon>https://</InputLeftAddon>
                        <Input type="text" required value={url} onChange={(e) => setUrl(e.target.value)} />
                     </InputGroup>
                  </FormControl>

                  <FormControl my={4}>
                     <FormLabel color="gray.500" fontWeight="bold">Title</FormLabel>
                     <Input required placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                  </FormControl>

                  <FormControl my={4}>
                     <FormLabel color="gray.500" fontWeight="bold">Description</FormLabel>
                     <Textarea rows={3} required resize="none" value={description} onChange={(e) => setDescription(e.target.value)} />
                     <FormHelperText color="fg.subtle">Write a short description about the tool or service</FormHelperText>
                  </FormControl>

                  <ModalFooter>
                     <Button isLoading={loading} colorScheme="purple" mr={3} onClick={onClose}>
                        Close
                     </Button>
                     <Button isLoading={loading} variant="outline" type="submit">Submit</Button>
                  </ModalFooter>
               </form>
            </ModalBody>
         </ModalContent>
      </Modal>
   );
}