import React from 'react';
import {
   Button,
   IconButton,
   Stack,
   StackDivider,
   Text,
   Link,
   Skeleton,
   SkeletonText,
   Tag
} from '@chakra-ui/react';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';

export class ArticlesSkeleton extends React.Component {

   render() {
      return (
         <React.Fragment>
            <Stack justify="space-between" direction="row" spacing="4">
               <Stack flex={1} spacing="0.5" fontSize="sm">
                  <Skeleton mb={2}>
                     <Text color="gray.800" fontWeight="bold">
                        <Link href={`#`}>Average Article title length will go here as a skeleton</Link>
                     </Text>
                  </Skeleton>
                  <Text color="gray.500">
                     <SkeletonText noOfLines={3} spacing={2} />
                  </Text>
               </Stack>
               <Stack>
                  <Skeleton borderRadius={6}>
                     <Tag bg="gray.600" size="sm" textColor="gray.100" alignSelf="center" justifySelf="center" textAlign="center">11/11/11</Tag>
                  </Skeleton>
                  <Stack direction={{
                     base: 'column',
                     sm: 'row'
                  }} spacing={{
                     base: '0',
                     sm: '1'
                  }}>
                     <Skeleton>
                        <IconButton as="a" title="Edit Article" href={`#`} icon={<FiEdit2 />} variant="tertiary" aria-label="Edit Article" />
                     </Skeleton>
                     <Skeleton>
                        <Button title="Delete Article" variant="tertiary" aria-label="Delete Article"><FiTrash2 />
                        </Button>
                     </Skeleton>
                  </Stack>
               </Stack>
            </Stack>
            {this.props.id !== this.props.length - 1 && <StackDivider />}
         </React.Fragment>
      );
   }
}
