import React from 'react';
import {
   Stack,
   StackDivider,
   Text, SkeletonText,
   HStack,
   Image,
   Skeleton,
   Icon
} from '@chakra-ui/react';
import { FiChevronRight } from 'react-icons/fi';

export function ToolsSkeleton(props) {
   return (
      <React.Fragment >
         <Stack {...props} flex={1} direction="row" spacing="4">
            <HStack flex={1} spacing={3}>
               <Skeleton borderRadius={6}>
                  <Image bg="gray.200" width={100} borderRadius='6px' boxSize='100px' src={``} />
               </Skeleton>
               <Stack flex={1} spacing="0.5" fontSize="sm">
                  <Skeleton mb={2}>
                     <Text color="gray.800" fontWeight="bold">
                        This is where the tool title goes
                     </Text>
                  </Skeleton>
                  <SkeletonText noOfLines={3} spacing={2} />
               </Stack>

               <Icon as={FiChevronRight} boxSize="30" color="gray.400" />
            </HStack>
         </Stack>
         <StackDivider />
      </React.Fragment>
   );
}
