import React, { useEffect } from 'react';
import { Box, Container } from '@chakra-ui/react'
import { TopNavbar } from '../shared/TopNavbar';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { LatestArticles } from './LatestArticles';
import { LatestTools } from './LatestTools';



const Dashboard = () => {
   const navigate = useNavigate();
   const [user, loading] = useAuthState(auth);

   useEffect(() => {
      document.title = "Dashboard | AI Empowerment";
   }, []);

   useEffect(() => {
      if (loading) return;
      if (!user) return navigate("/login");
   }, [user, loading, navigate]);

   return (
      <Box bg={'gray.50'} minH="100vh">
         <TopNavbar user={user} loading={loading} />
         <Box
            as="section"
            py={{
               base: '4',
               md: '8',
            }}
         >
            <Container maxW="6xl">
               <Box
                  bg="white"
                  boxShadow="sm"
                  borderRadius="lg"
                  p={{
                     base: '4',
                     md: '6',
                  }}
               >
                  <LatestArticles />
               </Box>
               
               <Box
                  bg="white"
                  boxShadow="sm"
                  borderRadius="lg"
                  p={{
                     base: '4',
                     md: '6',
                  }}
                  my={10}
               >
                  <LatestTools />
               </Box>
            </Container>
         </Box>
      </Box>
   );
};

export default Dashboard;


