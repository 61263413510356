
import { Text, HStack, Image } from '@chakra-ui/react'

export function Logo() {
  return (
    <HStack spacing="4">
      <Image src="/images/logo.svg" alt="Logo" width="40px" maxWidth={"40px"} />
      <Text mb="0" fontWeight="bold" color='blackAlpha.900'>AI Empowerment</Text>
    </HStack>
  )
}

export function LogoSymbol() {
  return (
    <Image src="/images/logo.svg" alt="Logo" width="40px" maxWidth={"40px"} />
  )
}
